.App {
  text-align: center;
  min-height:100vh;
  background-image: linear-gradient(to top, #fbc2eb 0%, #a6c1ee 100%);
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.search-form{
  min-height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-bar{
  width: 50%;
  border:none;
  padding:10px;
}

.search-button.btn.btn-primary{
  background: skyblue;
  border:none;
  padding:10px 20px;
  color:white;
}

.recipes{
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}