.recipe{
    margin: 20px;
    display: flex;
    flex-direction: column;
    background: white;
    align-items: center;    
    width: 22vw;
    box-shadow: 0 1px 6px 0 rgba(32, 33, 36, .28);
    padding: 10px;
}

@media (max-width: 1024px){
    .recipe{
        width: 44vw;
    }
}

@media (max-width: 500px){
    .recipe{
        width: 90vw;
    }

}

h1{    
    font-weight: 400;
    margin-bottom: 0px;
    padding-top: 20px;
    padding-bottom: 5px;
    font-size: 32px;
}

.image{
    width: 100%;
}

.ul{
    text-align: left;
    width: 100%;
}
